$(document).ready(() => {
	$(".core-menu li").hover(
		function(){
			$(this).children('ul').slideDown('fast');
		}, 
		function () {
			$('ul', this).slideUp('fast');
		});
		$(".hamburger-menu").click(function(){
		  $(".burger-1, .burger-2, .burger-3").toggleClass("open");
			$(".core-menu").slideToggle("fast");
		});

	$("form.validation").validationEngine({
		showPrompts: false,
		addFailureCssClassToField: "error",
		scroll: false,
		onFieldFailure: function (field) {
			if (field) {
				if (field.is(":radio")) {
					$(field).closest(".radio").addClass("error");
				}
			}
		},
		onFieldSuccess: function (field) {
			if (field) {
				if (field.is(":radio")) {
					$(field).closest(".radio").removeClass("error");
				}
			}
		},
	});
	$("form.validation [class*=validate]").on("change blur keyup", function () {
		$(this).validationEngine("validate");
	});

	$("body").on("click", "form.ajax button[type=submit]", function (e) {
		e.preventDefault();
		postFormAjax($(this).closest("form"));
		return false;
	});

	$("[data-mask]").each(function () {
		const type = $(this).data("mask");
		switch (type) {
			case "phone":
				$(this).inputmask({
					showMaskOnHover: false,
					mask: "(+994*) ###-##-##",
					definitions: {
						"#": {
							validator: "[0-9]",
							cardinality: 1,
							casing: "lower",
						},
						"*": {
							validator: "(10|50|51|55|60|70|77|99)",
							cardinality: 2,
							prevalidator: [{ validator: "[15679]", cardinality: 1 }],
						},
					},
				});
				break;
			case "number":
			case "decimal":
			case "integer":
				$(this).inputmask(type, { rightAlign: false });
				break;
		}
	});

	$("[data-match-height]").each(function () {
		$(this).find($(this).attr("data-match-height")).matchHeight();
	});

	if ($("[data-body-class]").length > 0) {
		$("body").addClass($("[data-body-class]:first").attr("data-body-class"));
	}

	$("#header .search .toggle").on("click", function () {
		$("#search_popup").addClass("open");
		setTimeout(() => $("#search_popup").addClass("animate"), 10);
	});
	$("#search_popup .back").on("click", function () {
		$("#search_popup").removeClass("animate");
		setTimeout(() => $("#search_popup").removeClass("open"), 400);
	});

	if ($(".home-partners").length) {
		const homePartners = tns({
			container: ".home-partners .slider",
			items: 1,
			gutter: 15,
			edgePadding: 44,
			controlsText: ["", ""],
			nav: false,
			loop: false,
			responsive: {
				550: {
					items: 5,
				},
			},
		});
	}
	if ($(".news-detail .slider,.text-page .slider").length) {
		tns({
			container: ".news-detail .slider,.text-page .slider",
			items: 1,
			gutter: 15,
			controlsText: ["", ""],
			nav: false,
			loop: false,
			responsive: {
				550: {
					items: 2,
				},
			},
		});
	}
	if ($(".home-projects .slider").length) {
		tns({
			container: ".home-projects .slider",
			controlsText: ["", ""],
			nav: false,
			loop: false,
			controls: false,
			responsive: {
				0: {
					items: 1.5,
					gutter: 15,
				},
				"992": {
					items: 3,
					gutter: 30,
				},
			},
		});
	}

	if ($(".menu").length) {
		$(".menu .sub:has(ul) > a").click(function () {
			$(this).parent().toggleClass("active");
		});
		const path = location.pathname;
		if ($('.menu a[href*="' + path + '"]')) {
			const mp = $('.menu a[href*="' + path + '"]').parent();
			mp.addClass("active");
			if (!mp.parent().hasClass("menu"))
				mp.parent().parent().addClass("active");
		}
	}

	$(window).scroll(function () {
		if ($(window).scrollTop() > 200) {
			$(".back-to-top").addClass("show");
		} else {
			$(".back-to-top").removeClass("show");
		}
	});
	$(".back-to-top").on("click", function (e) {
		e.preventDefault();
		$("html, body").animate({ scrollTop: 0 }, "200");
	});

	const media = window.matchMedia("(max-width: 991px)");
	mobileSupport(media);
	media.addListener(mobileSupport);

	if ($(".home-graph").length > 0) {
		$(".home-graph .count").each(function () {
			const id = uniqueId();
			const val = $(this).text() * 1;
			$(this).attr("id", id);
			const countUp = new CountUp(this, 0, val, 0, 2);
			$(this).data("countup", countUp);
		});
		$(window).on("resize scroll", function () {
			if ($(".home-graph").isInViewport()) {
				$(".home-graph .count").each(function () {
					$(this).data("countup").start();
				});
			}
		});
	}
	if(location.href.indexOf('index')>0)$('body').addClass('home');
});
(function ($) {
	$.fn.countup = function (params) {
		// make sure dependency is present
		if (typeof CountUp !== "function") {
			console.error(
				"countUp.js is a required dependency of countUp-jquery.js."
			);
			return;
		}

		var defaults = {
			startVal: 0,
			decimals: 0,
			duration: 2,
		};

		if (typeof params === "number") {
			defaults.endVal = params;
		} else if (typeof params === "object") {
			$.extend(defaults, params);
		} else {
			console.error(
				"countUp-jquery requires its argument to be either an object or number"
			);
			return;
		}

		this.each(function (i, elem) {
			var countUp = new CountUp(
				elem,
				defaults.startVal,
				defaults.endVal,
				defaults.decimals,
				defaults.duration,
				defaults.options
			);

			countUp.start();
		});

		return this;
	};
})(jQuery);
$.fn.isInViewport = function () {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();
	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();
	return elementBottom > viewportTop && elementTop < viewportBottom;
};
window.unique_id = 0;
function uniqueId() {
	window.unique_id++;
	return "asgUniqueId_" + window.unique_id;
}
$.is_mobile = false;
const mobileSupport = (media) => {
	if (media.matches) {
		$.is_mobile = true;
		if ($(".menu-overlay .menu").length)
			$(".menu-overlay .sticky").unstick();

		if (!$(".mobile-menu nav").length) {
			$("#header .lang").clone().appendTo(".mobile-menu .top");
			$("#header nav").clone().appendTo(".mobile-menu");
			$(".mobile-menu nav > ul > li:has(ul) a").click(function () {
				$(this).parent().toggleClass("active");
				$(".mobile-menu").toggleClass("nav_open");
			});
			$(".mobile-menu .close").click(function (e) {
				e.preventDefault();
				$(".mobile-menu nav li.active").removeClass("active");
				$(".mobile-menu").removeClass("nav_open active");
				setTimeout(() => $(".mobile-menu").removeClass("animate"), 400);
				$("body").removeClass("disable_scroll");
			});
			$(".open-mobile-menu").click(function () {
				$(".mobile-menu").toggleClass("animate");
				setTimeout(() => $(".mobile-menu").toggleClass("active"), 10);
				$("body").addClass("disable_scroll");
			});
		}

		$("[data-mobile-pos]").each(function () {
			const pos = $(this).attr("data-mobile-pos").split(",");
			const elem = $(this)
				.clone()
				.removeClass("hidden-xs")
				.removeAttr("data-mobile-pos")
				.addClass("cfm");
			if (pos[0] == "after") elem.insertAfter(pos[1]);
			else elem.insertBefore(pos[1]);
		});

		$(document).trigger("mobile_on");
	} else {
		$.is_mobile = false;

		if ($(".menu-overlay .menu").length)
			$(".menu-overlay .sticky").sticky({
				topSpacing: 105,
				zIndex: 2,
				bottomSpacing: $("#footer").outerHeight() + 400,
			});

		if ($(".media .tabs").length && !!window.mediaTabs) {
			window.mediaTabs.destroy();
			window.mediaTabs = null;
		}
		$(".cfm").remove();
		$(document).trigger("mobile_off");
	}
};

function postFormAjax(form) {
	if (form.validationEngine("validate") && form.attr("request") != 1) {
		let url = form.attr("action");

		const disabled = [];

		form.find(":disabled").each(function () {
			disabled.push(this);
			$(this).prop("disabled", false).removeProp("disabled");
		});
		let data = form.serialize();

		$.each(disabled, function () {
			$(this).prop("disabled", true);
		});

		$.ajax({
			url: url,
			type: "POST",
			dataType: "json",
			cache: false,
			data: data,
			beforeSend: function () {
				form.attr("request", 1);
				if (
					form.css("position") != "absolute" &&
					form.css("position") != "relative"
				)
					form.css("position", "relative");
				$(
					'<div class="loading"><div class="lds-ripple"><div></div><div></div></div></div>'
				).appendTo(form);
			},
			success: function (data) {
				form.attr("request", 0);
				if (data.code == 200) {
					form.addClass("success");
					form.find('button[type="submit"]').prop("disabled", true);
				} else if (typeof data.message != "undefined") {
					alertify.error(data.message);
				}
			},
			error: function (x, e) {
				form.attr("request", 0);
				var e_msg = "";
				if (x.status == 0)
					e_msg = "You are offline!!<br> Please Check Your Network.";
				else if (x.status == 404) e_msg = "Requested URL not found.";
				else if (x.status == 500)
					e_msg = "Internel Server Error." + x.responseText;
				else if (e == "parsererror")
					e_msg = "Error.<br>Parsing JSON Request failed.";
				else if (e == "timeout") e_msg = "Request Time out.";
				else if (e == "error") e_msg = "Request error.";
				else if (e == "abort") e_msg = "Request abort.";
				else e_msg = "Unknow Error.<br>" + x.responseText;
			},
			complete: function () {
				$(".loading").remove();
			},
		});
	}
}
